<template>
  <div class="container">
    <div class="handle-box">
      <el-form
        :inline="true"
        ref="aaSpecialForm"
        :model="form"
        :rules="rules"
        :label-width="this.$widthRatio * 120 + 'px'"
      >
        <el-collapse v-model="aaSpecialConfigActiveName" accordion>
          <el-collapse-item title="AA Special" name="aaSpecialInnerSetting">
            <div v-for="(item, index) in form.items" :key="index">
              <el-form-item label="Key">
                <el-input v-model="item.key"></el-input>
              </el-form-item>
              <el-form-item label="Value">
                <el-input v-model="item.value"></el-input>
              </el-form-item>
              <el-button size="mini" @click="handleAddItem(index, item)">
                <el-icon>
                  <plus />
                </el-icon>
              </el-button>
              <el-button size="mini" @click="handleDeleteItem(index, item)">
                <el-icon> <delete /> </el-icon>
              </el-button>
              <el-button size="mini" @click="handleMoveUpItem(index, item)">
                <el-icon> <top /> </el-icon>
              </el-button>
              <el-button size="mini" @click="handleMoveDownItem(index, item)">
                <el-icon> <bottom /> </el-icon>
              </el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div>
        <span class="dialog-footer">
          <el-button type="primary" @click="updateConfig"> Update </el-button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getData, postData } from "../../service/api";

export default {
  data() {
    return {
      controllerUrl: "/common",
      aaSpecialConfigActiveName: "aaSpecialInnerSetting",
      rules: {},
      form: {
        items: [],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getData(`${this.controllerUrl}/loadAASpecialConfig`).then((res) => {
        if (res.result && res.code === "200") {
          this.form = res.result;
        }
      });
    },
    updateConfig() {
      this.$refs.aaSpecialForm.validate((valid) => {
        if (valid) {
          postData(`${this.controllerUrl}/saveAASpecialConfig`, this.form).then((res) => {
            if (res.result && res.code === "200") {
              this.$appSetting.init();
              this.$message.success("Update successfully");
            } else {
              this.$message.error("Update failed, error message: " + res.message);
            }
          });
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
    handleAddItem(index) {
      this.form.items.splice(index + 1, 0, {});
    },
    handleDeleteItem(index) {
      this.form.items.splice(index, 1);
    },
    handleMoveUpItem(index) {
      if (index <= 0) return;
      let delItems = this.form.items.splice(index, 1);
      this.form.items.splice(index - 1, 0, delItems[0]);
    },
    handleMoveDownItem(index) {
      if (index >= this.form.items.length - 1) return;
      let delItems = this.form.items.splice(index, 1);
      this.form.items.splice(index + 1, 0, delItems[0]);
    },
  },
};
</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.el-form-item__label {
  width: 120px;
}
</style>
