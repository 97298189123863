<template>
  <div class="container">
    <div class="handle-box">
      <el-form
        :inline="true"
        ref="educationConfigForm"
        :model="form"
        :width="this.$widthRatio * 120 + 'px'"
      >
        <el-collapse v-model="educationConfigActiveName" accordion>
          <el-collapse-item title="JETree Branches Setting" name="lessonTypesSetting">
            <div v-for="(item, index) in form.lessonTypes.items" :key="index">
              <el-form-item :prop="`lessonTypes.items.${index}.name`" label="Name">
                <el-input v-model="item.name" readonly></el-input>
              </el-form-item>
              <el-form-item :prop="`lessonTypes.items.${index}.name`" label="Color">
                <el-color-picker
                  v-model="item.branchColor"
                  show-alpha
                  size="small"
                  :predefine="options.predefineColors"
                />
              </el-form-item>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Lessons' Scopes Setting" name="scopesSetting">
            <div v-for="(item, index) in form.scopes.items" :key="index">
              <el-form-item :prop="`scopes.items.${index}.name`" label="Name">
                <el-input v-model="item.name" :readonly="!item.isNew"></el-input>
              </el-form-item>
              <el-form-item :prop="`scopes.items.${index}.title`" label="Title">
                <el-input v-model="item.title"></el-input>
              </el-form-item>
              <el-form-item :prop="`scopes.items.${index}.title`" label="Background">
                <el-color-picker
                  v-model="item.leaf_BG_Color"
                  show-alpha
                  size="small"
                  :predefine="options.predefineColors"
                />
              </el-form-item>
              <el-button size="mini" @click="handleAddObjectItem('scopes', index, item)">
                <el-icon>
                  <plus />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleDeleteObjectItem('scopes', index, item)"
              >
                <el-icon>
                  <delete />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveUpObjectItem('scopes', index, item)"
              >
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveDownObjectItem('scopes', index, item)"
              >
                <el-icon> <bottom /> </el-icon>
              </el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item
            v-if="false"
            title="Knowledge Points"
            name="knowledgePointsSetting"
          >
            <div v-for="(item, index) in form.knowledgePoints" :key="index">
              <el-form-item :prop="`knowledgePoints.${index}`" label="Name">
                <el-input v-model="form.knowledgePoints[index]"></el-input>
              </el-form-item>
              <el-button
                size="mini"
                @click="handleAddItem('knowledgePoints', index, item)"
              >
                <el-icon>
                  <plus />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleDeleteItem('knowledgePoints', index, item)"
              >
                <el-icon>
                  <delete />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveUpItem('knowledgePoints', index, item)"
              >
                <el-icon> <top /> </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveDownItem('knowledgePoints', index, item)"
              >
                <el-icon> <bottom /> </el-icon>
              </el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item
            v-if="false"
            title="Marks Percentage Occupation Setting"
            name="marksPercentageOccupationSetting"
          >
            <div
              v-for="(item, index) in form.marksPercentageOccupation.items"
              :key="index"
            >
              <el-form-item
                :prop="`marksPercentageOccupation.items.${index}.name`"
                label="Name"
              >
                <el-input v-model="item.name" :readonly="!item.isNew"></el-input>
              </el-form-item>
              <el-form-item
                :prop="`marksPercentageOccupation.items.${index}.percentage`"
                label="Percentage"
              >
                <el-input v-model="item.percentage"></el-input>
              </el-form-item>
              <el-button
                size="mini"
                @click="handleAddObjectItem('marksPercentageOccupation', index, item)"
              >
                <el-icon>
                  <plus />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleDeleteObjectItem('marksPercentageOccupation', index, item)"
              >
                <el-icon>
                  <delete />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveUpObjectItem('marksPercentageOccupation', index, item)"
              >
                <el-icon> <top /> </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="
                  handleMoveDownObjectItem('marksPercentageOccupation', index, item)
                "
              >
                <el-icon> <bottom /> </el-icon>
              </el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Points Template Setting" name="pointsTemplatesSetting">
            <div v-for="(item, index) in form.pointsTemplates.items" :key="index">
              <el-form-item :prop="`pointsTemplates.items.${index}.name`" label="Name">
                <el-input
                  :readonly="item.predefine || !item.isNew"
                  v-model="item.name"
                  style="width: 140px"
                ></el-input>
              </el-form-item>
              <el-form-item :prop="`pointsTemplates.items.${index}.title`" label="Title">
                <el-input v-model="item.title" style="width: 140px"></el-input>
              </el-form-item>
              <el-form-item
                :prop="`pointsTemplates.items.${index}.points`"
                label="Points"
              >
                <el-input v-model="item.points" style="width: 80px"></el-input>
              </el-form-item>
              <el-form-item :prop="`pointsTemplates.items.${index}.issuedType`" label="">
                <el-select v-model="item.issuedType" placeholder="Issued Type...">
                  <el-option label="Normal" value="Normal"></el-option>
                  <el-option label="Pending" value="Pending"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :prop="`pointsTemplates.items.${index}.predefine`"
                label="Predefine"
              >
                <el-checkbox
                  :disabled="item.predefine"
                  v-model="item.predefine"
                ></el-checkbox>
              </el-form-item>
              <el-form-item
                :prop="`pointsTemplates.items.${index}.predefine`"
                label="Customized"
              >
                <el-checkbox
                  :readonly="item.predefine || !item.isNew"
                  v-model="item.customized"
                ></el-checkbox>
              </el-form-item>
              <el-button
                size="mini"
                @click="handleAddObjectItem('pointsTemplates', index, item)"
              >
                <el-icon>
                  <plus />
                </el-icon>
              </el-button>
              <el-button
                v-if="!item.predefine"
                size="mini"
                @click="handleDeleteObjectItem('pointsTemplates', index, item)"
              >
                <el-icon>
                  <delete />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveUpObjectItem('pointsTemplates', index, item)"
              >
                <el-icon> <top /> </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveDownObjectItem('pointsTemplates', index, item)"
              >
                <el-icon> <bottom /> </el-icon>
              </el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item
            title="Top Up Points Template Setting"
            name="topUpPointsTemplatesSetting"
          >
            <div v-for="(item, index) in form.topUpPointsTemplates.items" :key="index">
              <el-form-item
                :prop="`topUpPointsTemplates.items.${index}.points`"
                label="Points"
              >
                <el-input
                  @change="
                    handleItemElementChange(
                      'topUpPointsTemplates',
                      item,
                      'points',
                      $event
                    )
                  "
                  v-model="item.points"
                  style="width: 80px"
                ></el-input>
              </el-form-item>
              <el-form-item
                :prop="`topUpPointsTemplates.items.${index}.discount`"
                label="Discount"
              >
                <el-input
                  @change="
                    handleItemElementChange(
                      'topUpPointsTemplates',
                      item,
                      'discount',
                      $event
                    )
                  "
                  v-model="item.discount"
                  style="width: 80px"
                ></el-input>
              </el-form-item>
              <el-form-item
                :prop="`topUpPointsTemplates.items.${index}.unitPrice`"
                label="Per Unit Price"
              >
                <el-input
                  disabled
                  v-model="item.unitPrice"
                  style="width: 80px"
                ></el-input>
              </el-form-item>
              <el-form-item
                :prop="`topUpPointsTemplates.items.${index}.disabled`"
                label="Disabled"
              >
                <el-checkbox
                  :disabled="item.disabled"
                  v-model="item.disabled"
                ></el-checkbox>
              </el-form-item>
              <el-button
                size="mini"
                @click="handleAddObjectItem('topUpPointsTemplates', index, item)"
              >
                <el-icon>
                  <plus />
                </el-icon>
              </el-button>
              <el-button
                v-if="!item.predefine"
                size="mini"
                @click="handleDeleteObjectItem('topUpPointsTemplates', index, item)"
              >
                <el-icon>
                  <delete />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveUpObjectItem('topUpPointsTemplates', index, item)"
              >
                <el-icon> <top /> </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveDownObjectItem('topUpPointsTemplates', index, item)"
              >
                <el-icon> <bottom /> </el-icon>
              </el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item title="General Setting" name="generalSetting">
            <div v-for="(item, index) in form.keyValues.items" :key="index">
              <el-form-item :prop="`keyValues.items.${index}.key`" label="Name">
                <el-input v-model="item.key" :readonly="!item.isNew"></el-input>
              </el-form-item>
              <el-form-item :prop="`keyValues.items.${index}.value`" label="Value">
                <el-input v-model="item.value"></el-input>
              </el-form-item>
              <el-button
                size="mini"
                @click="handleAddObjectItem('keyValues', index, item)"
              >
                <el-icon>
                  <plus />
                </el-icon>
              </el-button>
              <el-button
                v-if="!item.predefine"
                size="mini"
                @click="handleDeleteObjectItem('keyValues', index, item)"
              >
                <el-icon>
                  <delete />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveUpObjectItem('keyValues', index, item)"
              >
                <el-icon> <top /> </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveDownObjectItem('keyValues', index, item)"
              >
                <el-icon> <bottom /> </el-icon>
              </el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>

      <div class="footer">
        <el-button type="primary" @click="updateConfig"> Update </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getData, postData } from "../../service/api";

export default {
  data() {
    return {
      controllerUrl: "/common",
      educationConfigActiveName: null,
      options: {
        predefineColors: [
          "#ff4500",
          "#ff8c00",
          "#ffd700",
          "#90ee90",
          "#00ced1",
          "#1e90ff",
          "#c71585",
          "rgba(255, 69, 0, 0.68)",
          "rgb(255, 120, 0)",
          "hsv(51, 100, 98)",
          "hsva(120, 40, 94, 0.5)",
          "hsl(181, 100%, 37%)",
          "hsla(209, 100%, 56%, 0.73)",
          "#c7158577",
        ],
      },
      rules: {},
      form: {
        knowledgePoints: [],
        scopes: {
          items: [],
        },
        lessonTypes: {
          items: [],
        },
        marksPercentageOccupation: {
          items: [],
        },
        pointsTemplates: {
          items: [],
        },
        topUpPointsTemplates: {
          items: [],
        },
        keyValues: {
          items: [],
        },
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getData(`${this.controllerUrl}/loadEducationConfig`).then((res) => {
        console.log(res);
        this.form = res.result;
        if (this.form.knowledgePoints.length == 0) {
          this.form.knowledgePoints = [""];
        }

        if (this.form.scopes.items.length == 0) {
          this.form.scopes.items = [{ isNew: true }];
        } else {
          for (let i = 0; i < this.form.scopes.items.length; i++) {
            this.form.scopes.items[i].isNew = false;
          }
        }
        if (this.form.lessonTypes.items.length == 0) {
          this.form.lessonTypes.items = [{ isNew: true }];
        } else {
          for (let i = 0; i < this.form.lessonTypes.items.length; i++) {
            this.form.lessonTypes.items[i].isNew = false;
          }
        }
        if (this.form.marksPercentageOccupation.items.length == 0) {
          this.form.marksPercentageOccupation.items = [{ isNew: true }];
        } else {
          for (let i = 0; i < this.form.marksPercentageOccupation.items.length; i++) {
            this.form.marksPercentageOccupation.items[i].isNew = false;
          }
        }
        if (this.form.pointsTemplates.items.length == 0) {
          this.form.pointsTemplates.items = [{ isNew: true }];
        } else {
          for (let i = 0; i < this.form.pointsTemplates.items.length; i++) {
            this.form.pointsTemplates.items[i].isNew = false;
          }
        }
        if (this.form.topUpPointsTemplates.items.length == 0) {
          this.form.topUpPointsTemplates.items = [{ unitPrice: null, isNew: true }];
        } else {
          for (let i = 0; i < this.form.topUpPointsTemplates.items.length; i++) {
            this.form.topUpPointsTemplates.items[
              i
            ].unitPrice = this.calculatePerUnitPrice(
              this.form.topUpPointsTemplates.items[i]
            );
            this.form.topUpPointsTemplates.items[i].isNew = false;
          }
        }
        if (this.form.keyValues.items.length == 0) {
          this.form.keyValues.items = [{ isNew: true }];
        } else {
          for (let i = 0; i < this.form.keyValues.items.length; i++) {
            this.form.keyValues.items[i].isNew = false;
          }
        }
      });
    },
    updateConfig() {
      this.$refs.educationConfigForm.validate((valid) => {
        if (valid) {
          postData(`${this.controllerUrl}/saveEducationConfig`, this.form).then((res) => {
            if (res.result && res.code === "200") {
              this.$appSetting.init();
              this.$message.success("Update successfully");
            } else {
              this.$message.error("Update failed, error message: " + res.message);
            }
          });
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
    handleAddObjectItem(name, index) {
      this.form[name].items.splice(index + 1, 0, { isNew: true });
    },
    handleDeleteObjectItem(name, index) {
      this.form[name].items.splice(index, 1);
    },
    handleMoveUpObjectItem(name, index) {
      if (index <= 0) return;
      let delItems = this.form[name].items.splice(index, 1);
      this.form[name].items.splice(index - 1, 0, delItems[0]);
    },
    handleMoveDownObjectItem(name, index) {
      if (index >= this.form[name].items.length - 1) return;
      let delItems = this.form[name].items.splice(index, 1);
      this.form[name].items.splice(index + 1, 0, delItems[0]);
    },

    handleAddItem(name, index) {
      this.form[name].splice(index + 1, 0, "");
    },
    handleDeleteItem(name, index) {
      this.form[name].splice(index, 1);
    },
    handleMoveUpItem(name, index) {
      if (index <= 0) return;
      let delItems = this.form[name].splice(index, 1);
      this.form[name].splice(index - 1, 0, delItems[0]);
    },
    handleMoveDownItem(name, index) {
      if (index >= this.form[name].length - 1) return;
      let delItems = this.form[name].splice(index, 1);
      this.form[name].splice(index + 1, 0, delItems[0]);
    },
    handleItemElementChange(name, itemData, itemName, val) {
      if (name === "topUpPointsTemplates") {
        if (itemName === "points") {
          itemData.name = `Points_${val}`;
        }
        if (itemName === "discount") {
          itemData.unitPrice = this.calculatePerUnitPrice(itemData);
        }
      }
    },
    calculatePerUnitPrice(itemData) {
      let ratioForCashToPoints = this.$appSetting.getGenericValue(
        // 10 points for $1
        "CASH_TO_POINTS_RATIO",
        10
      );
      return itemData.points
        ? this.$formatter.formatDecimal(
            (1 / ratioForCashToPoints) * (1 - itemData.discount),
            3
          )
        : null;
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.el-form-item__label {
  width: 120px;
}
.footer {
  margin-top: 20px;
}
</style>
