<template>
  <el-form
    ref="mainForm"
    :model="form"
    :rules="rules"
    :lazy="true"
    :label-width="this.$widthRatio * 200 + 'px'"
  >
    <el-tabs v-model="activeName" :lazy="true">
      <el-tab-pane label="Education Setting" name="educationSetting">
        <education-config-setting />
      </el-tab-pane>
      <el-tab-pane label="Password Rule Setting" name="passwordRuleSetting">
        <password-rule-setting />
      </el-tab-pane>
      <el-tab-pane label="Site Setting" name="siteSetting">
        <site-config-setting />
      </el-tab-pane>
      <el-tab-pane label="Paymate Setting" name="paymateSetting">
        <paymate-setting />
      </el-tab-pane>
      <el-tab-pane v-if="isAA" label="AASpecial Setting" name="aaSpecialSetting">
        <AASpecialSetting />
      </el-tab-pane>
      <el-tab-pane v-if="isAA" label="Upload File" name="uploadFileTab">
        <div>
          Relative path:
          <el-input
            v-model="path"
            placeholder="Upload to..."
            :clearable="true"
          ></el-input>
        </div>

        <div style="margin-top: 10px">
          <el-upload
            ref="upload"
            class="admin-upload"
            :action="getUploadUrl()"
            :limit="1"
            :on-exceed="handleExceed"
            :on-success="fileUploadSuccess"
            :auto-upload="false"
            :headers="headers"
          >
            <template #trigger>
              <el-button type="primary">Select a file</el-button>
            </template>
            <el-button class="ml-3" type="success" @click="submitUpload">
              upload to server
            </el-button>
            <template #tip>
              <div class="el-upload__tip text-red">
                limit 1 file, new file will cover the old file
              </div>
            </template>
          </el-upload>
        </div>

        <div style="margin-top: 40px">
          Delete a file from a relative path
          <br />
          <el-input
            v-model="filePath"
            placeholder="File to..."
            :clearable="true"
          ></el-input>
          <el-button type="success" @click="deleteFile"> Remove the file </el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-form>
</template>
<script>
import PasswordRuleSetting from "./PasswordRuleSetting.vue";
import SiteConfigSetting from "./SiteConfigSetting.vue";
import EducationConfigSetting from "./EducationConfigSetting.vue";
import AASpecialSetting from "./AASpecialSetting.vue";
import PaymateSetting from "./PaymateSetting.vue";
import { deleteData } from "../../service/api";

export default {
  components: {
    PasswordRuleSetting,
    SiteConfigSetting,
    EducationConfigSetting,
    AASpecialSetting,
    PaymateSetting,
  },
  data() {
    return {
      filePath: "",
      path: "Files\\Profiles",
      activeName: "educationSetting",
      isAA: false,
      headers: { authorization: `Bearer ${this.$user.getAccessToken()}` },
    };
  },
  created() {
    let key = this.$route.query.g;
    if (key === "92260B44-4BEE-4211-B278-F9A88C2A8C0D") {
      this.isAA = true;
    }
  },
  methods: {
    submitUpload: function () {
      if (!this.path) {
        this.$message.error("Please input a path to go on!");
        return false;
      }
      if (this.$refs.upload.uploadFiles.length <= 0) {
        this.$message.error("Please select a file to go on!");
        return false;
      }
      this.$refs.upload.submit();
    },
    handleExceed: function (files) {
      this.$refs.upload.clearFiles();
      this.$refs.upload.handleStart(files[0]);
    },
    getUploadUrl: function () {
      return `${this.$appSetting.getAPIRoot()}/file/uploadFile?path=${this.path}`;
    },
    fileUploadSuccess(response) {
      this.$refs.upload.clearFiles();
      if (response.result) {
        this.$message({
          message: "Upload successfully.",
          type: "success",
        });
      } else {
        this.$message.error(response.message);
      }
    },
    deleteFile() {
      deleteData("/file/removeFile", "", { path: this.filePath }).then((res) => {
        if (res.result && res.code === "200") {
          this.$message.success("Deleted successfully");
        } else {
          this.$message.error(`Login failed, error message: ${res.message}`);
        }
      });
    },
  },
};
</script>
<style>
.el-upload {
  display: inline;
}
</style>
<style scoped>
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.buttons {
  text-align: center;
}
</style>
